// 运营中心模块状态记录列表
export const recordStatusList = [
    {
        id: 0,
        name: '审核中'
    },
    {
        id: 1,
        name: '通过'
    },
    {
        id: 2,
        name: '驳回'
    }
];
