<template>
    <div class="brand-manage-page">
        <div v-if="!dialogAuditBrandVisible">
            <div class="brand-manage-header">
                <div class="header-left">
                    <!--                <el-select size="small" v-model="brandManageForm.status" clearable placeholder="品牌状态">-->
                    <!--                    <el-option v-for="item in brandStatusList" :key="item.id" :label="item.name" :value="item.id">-->
                    <!--                    </el-option>-->
                    <!--                </el-select>-->
                    <el-input
                        size="small"
                        v-model="brandManageForm.searchVal"
                        placeholder="请输入品牌名"
                        clearable
                    ></el-input>
                    <el-button size="small" type="primary" @click="init">查询</el-button>
                </div>
                <div class="header-right">
                    <!--                <el-button size="small" type="primary" @click="handleExport">导出</el-button>-->
                    <!--                <span style="margin: 0 20px; top: 2px">|</span>-->
                    <span style="top: 3px; margin-right: 20px">共{{ page.count }}条</span>
                    <!--                <el-button size="small" type="primary" @click="handleUploadAddToNewBrandView('add')"-->
                    <!--                    >上传新品牌</el-button-->
                    <!--                >-->
                </div>
            </div>
            <div class="brand-manage-table">
                <el-table
                    v-loading="loading"
                    :header-cell-style="{ background: '#F2F2F2' }"
                    :data="brandManageDataList"
                    border
                    align="center"
                    style="width: 100%"
                >
                    <el-table-column width="60" label="序号" type="index" :index="indexMethod"></el-table-column>
                    <el-table-column prop="b_number" width="240" label="编号" align="center"></el-table-column>
                    <!--              <el-table-column prop="Sort" label="置顶序号" align="center"></el-table-column>-->
                    <el-table-column prop="b_name" label="品牌名称" align="center" width="200">
                        <template v-slot="scope">
                            <div :class="[scope.row.is_change === 1 ? 'red-color' : '']">{{ scope.row.b_name }}</div>
                        </template>
                    </el-table-column>
                    <!--              <el-table-column prop="Describe" label="品牌介绍" align="center" width="150" show-overflow-tooltip>-->
                    <!--              </el-table-column>-->
                    <!--              <el-table-column prop="Logo" width="120" label="品牌LOGO" align="center">-->
                    <!--                <template #default="scope">-->
                    <!--                  <div v-if="scope.row.Logo">-->
                    <!--                    <el-button type="text" @click="previewBrandLogo(scope.$index)">查看</el-button>-->
                    <!--                    <el-image-viewer-->
                    <!--                        @close="closeViewerLogo(scope.$index)"-->
                    <!--                        v-if="showViewerLogo[scope.$index]"-->
                    <!--                        :url-list="[scope.row.Logo]"-->
                    <!--                    >-->
                    <!--                    </el-image-viewer>-->
                    <!--                  </div>-->
                    <!--                  <el-button type="text" v-else disabled>/</el-button>-->
                    <!--                </template>-->
                    <!--              </el-table-column>-->
                    <!--              <el-table-column prop="MasterMap" label="品牌主图" align="center">-->
                    <!--                <template #default="scope">-->
                    <!--                  <div v-if="scope.row.MasterMap">-->
                    <!--                    <el-button type="text" @click="previewBrandMainFigure(scope.$index)">查看</el-button>-->
                    <!--                    <el-image-viewer-->
                    <!--                        @close="closeViewerMasterMap(scope.$index)"-->
                    <!--                        v-if="showViewerMasterMap[scope.$index]"-->
                    <!--                        :url-list="[scope.row.MasterMap]"-->
                    <!--                    >-->
                    <!--                    </el-image-viewer>-->
                    <!--                  </div>-->
                    <!--                  <el-button type="text" v-else disabled>/</el-button>-->
                    <!--                </template>-->
                    <!--              </el-table-column>-->
                    <!--              <el-table-column prop="AuxiliaryChart" label="品牌副图" align="center">-->
                    <!--                <template #default="scope">-->
                    <!--                  <div v-if="scope.row.AuxiliaryChart">-->
                    <!--                    <el-button type="text" @click="previewbrandSubFigure(scope.$index)">查看</el-button>-->
                    <!--                    <el-image-viewer-->
                    <!--                        @close="closeViewerAuxiliaryChart(scope.$index)"-->
                    <!--                        v-if="showViewerAuxiliaryChart[scope.$index]"-->
                    <!--                        :url-list="[scope.row.AuxiliaryChart]"-->
                    <!--                    >-->
                    <!--                    </el-image-viewer>-->
                    <!--                  </div>-->
                    <!--                  <el-button type="text" v-else disabled>/</el-button>-->
                    <!--                </template>-->
                    <!--              </el-table-column>-->
                    <!--              <el-table-column label="品牌介绍(图片)" width="130" align="center">-->
                    <!--                <template #default="scope">-->
                    <!--                  <div v-if="scope.row.IntroducePicture && scope.row.IntroducePicture.length > 0">-->
                    <!--                    <el-button type="text" @click="previewBrandIntroduceFigure(scope.$index)">查看</el-button>-->
                    <!--                    <el-image-viewer-->
                    <!--                        @close="closeViewerIntroducePicture(scope.$index)"-->
                    <!--                        v-if="showViewerIntroducePicture[scope.$index]"-->
                    <!--                        :url-list="scope.row.IntroducePicture"-->
                    <!--                    >-->
                    <!--                    </el-image-viewer>-->
                    <!--                  </div>-->
                    <!--                  <el-button type="text" v-else disabled>/</el-button>-->
                    <!--                </template>-->
                    <!--              </el-table-column>-->
                    <!--              <el-table-column label="品牌视频" align="center">-->
                    <!--                <template #default="scope">-->
                    <!--                  <div v-if="scope.row.Video">-->
                    <!--                    <el-button type="text" @click="previewbrandVideo(scope.row)">查看</el-button>-->
                    <!--                  </div>-->
                    <!--                  <el-button type="text" v-else disabled>/</el-button>-->
                    <!--                </template>-->
                    <!--              </el-table-column>-->
                    <el-table-column prop="e_neme" label="企业名称" align="center"></el-table-column>
                    <el-table-column prop="b_updatetime" label="更新时间" align="center"></el-table-column>
                    <!--              <el-table-column prop="Mobile" label="关联手机号" align="center" width="120"></el-table-column>-->
                    <el-table-column label="操作" align="center" fixed="right" width="180">
                        <template v-slot="scope">
                            <el-button
                                @click="handleAuditBrandBtn(scope.row)"
                                type="text"
                                size="small"
                                :disabled="!auth.examine"
                                v-if="scope.row.is_change === 1"
                                >去审核</el-button
                            >
                            <!--                        <el-button @click="handleUploadAddToNewBrandView('view', scope.row)" type="text" size="small"-->
                            <!--                            >查看</el-button-->
                            <!--                        >-->
                            <!--                        <el-button @click="handleDeleteBtn(scope.row)" type="text" size="small">删除</el-button>-->
                            <!--                        <el-button-->
                            <!--                            v-if="scope.row.Status.Status == 1 || scope.row.Status.Status == 2"-->
                            <!--                            @click="handleChangeStatus(scope.row)"-->
                            <!--                            type="text"-->
                            <!--                            size="small"-->
                            <!--                            >{{ scope.row.Status.Status === 1 ? '停用' : '启用' }}</el-button-->
                            <!--                        >-->
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="text-align: right; margin: 30px 0 15px" v-if="brandManageDataList.length > 0">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page.page"
                    :page-sizes="[10, 30, 40, 50]"
                    :page-size="page.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="page.count"
                >
                </el-pagination>
            </div>
        </div>
        <el-dialog
            title="品牌视频"
            width="72%"
            top="35px"
            @close="closeVideo"
            append-to-body
            v-if="dialogBrandVideoVisible"
            v-model="dialogBrandVideoVisible"
            center
        >
            <video
                class="video"
                style="width: 100%; height: 100%; object-fit: cover"
                v-if="Video"
                controls
                :src="Video"
            ></video>
        </el-dialog>
        <!-- 上传、编辑新构件弹窗 -->
        <dialogFrame
            width="810px"
            :show="dialogUploadAddToViewBrandVisible"
            :title="action == 'add' ? '上传新品牌' : '查看品牌'"
            :footer="false"
        >
            <brandUploadAddEdit
                v-if="dialogUploadAddToViewBrandVisible"
                :show="dialogUploadAddToViewBrandVisible"
                :action="action"
                :brandManageEditData="brandManageEditData"
            ></brandUploadAddEdit>
        </dialogFrame>
        <!-- 审核品牌弹窗 -->
        <!--        <dialogFrame width="500px" :show="dialogAuditBrandVisible" @close="closeAuditDialog" title="" :footer="false">-->
        <auditBrandDialog
            v-if="dialogAuditBrandVisible"
            :show="dialogAuditBrandVisible"
            :brandAuditData="brandAuditData"
            @closeAuditDialog="closeAuditDialog"
        ></auditBrandDialog>
        <!--        </dialogFrame>-->
    </div>
</template>


<script>
import dialogFrame from '@/components/common/dialog/dialog-frame.vue';
import brandUploadAddEdit from './components/BrandUploadAddEdit.vue';
import auditBrandDialog from './components/AuditBrandInfoDialog.vue';
import { getList, download, del, enable } from '@/common/api/operatsCenter/brandManage';
export default {
    components: {
        dialogFrame,
        brandUploadAddEdit,
        auditBrandDialog
    },
    data() {
        return {
            brandManageForm: {
                // 状态
                status: '',
                searchVal: ''
            },
            page: {
                page: 1,
                pageSize: 10,
                count: 0
            },
            // 状态列表
            brandStatusList: [
                {
                    id: '',
                    name: '全部'
                }
            ],
            // 表格数据
            brandManageDataList: [],
            loading: false,
            // 是否显示上传/查看品牌弹窗
            dialogUploadAddToViewBrandVisible: false,
            // 弹窗操作类型
            action: '',
            // 编辑弹窗数据
            brandManageEditData: {},
            // 是否显示审核品牌弹窗
            dialogAuditBrandVisible: false,
            // 品牌审核弹窗数据
            brandAuditData: {},
            // 是否显示视频播放弹窗
            dialogBrandVideoVisible: false,
            showViewerLogo: [],
            showViewerAuxiliaryChart: [],
            showViewerMasterMap: [],
            showViewerIntroducePicture: [],
            Video: '',
            auth: []
        };
    },
    watch: {
        dialogUploadAddToViewBrandVisible(val) {
            if (val == false) {
                this.init();
            }
        }
    },
    created() {
        this.init();
    },
    methods: {
        closeVideo() {
            this.dialogBrandVideoVisible = false;
            this.Video = '';
        },
        closeViewerIntroducePicture(index) {
            this.showViewerIntroducePicture[index] = false;
        },
        closeViewerAuxiliaryChart(index) {
            this.showViewerAuxiliaryChart[index] = false;
        },
        closeViewerMasterMap(index) {
            this.showViewerMasterMap[index] = false;
        },
        closeViewerLogo(index) {
            this.showViewerLogo[index] = false;
        },
        // 查看品牌logo
        previewBrandLogo(index) {
            this.showViewerLogo[index] = true;
        },
        // 查看品牌主图
        previewBrandMainFigure(index) {
            this.showViewerMasterMap[index] = true;
        },
        // 查看品牌副图
        previewbrandSubFigure(index) {
            this.showViewerAuxiliaryChart[index] = true;
        },
        // 查看品牌介绍图
        previewBrandIntroduceFigure(index) {
            this.showViewerIntroducePicture[index] = true;
        },
        // 查看视频
        previewbrandVideo(row) {
            this.Video = row.Video;
            this.dialogBrandVideoVisible = true;
        },
        // 关闭品牌审核弹窗
        closeAuditDialog(value) {
            this.dialogAuditBrandVisible = value;
            this.init();
        },
        indexMethod(index) {
            return this.page.pageSize * (this.page.page - 1) + 1 + index;
        },
        init() {
            // this.loading = true;
            let dt = {
                Status: this.brandManageForm.status,
                BrandName: this.brandManageForm.searchVal,
                Page: this.page.page,
                Len: this.page.pageSize
            };
            getList(dt).then(res => {
                // console.log(res);
                this.page.count = res.count;
                this.brandManageDataList = res.result;
                this.auth = res.Power;
                if (this.brandStatusList.length == 1) {
                    for (let key in res.status) {
                        this.brandStatusList.push(res.status[key]);
                    }
                }
                // this.loading = false;
            });
        },
        brandStatusName(row) {
            return this.brandStatusList.find(val => val.id === row.status)?.name;
        },
        // 处理导出按钮
        handleExport() {
            this.$confirm('确认导出?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            })
                .then(() => {
                    let dt = {
                        Status: this.brandManageForm.status,
                        BrandName: this.brandManageForm.searchVal
                    };
                    download(dt).then(() => {
                        this.$message({
                            type: 'success',
                            message: '下载成功!',
                            center: true
                        });
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        // 处理去审核品牌操作
        handleAuditBrandBtn(row) {
            let { b_id } = row;
            this.brandAuditData = {
                b_id
            };
            this.dialogAuditBrandVisible = true;
        },
        // 处理上传/查看新品牌
        handleUploadAddToNewBrandView(type, row) {
            this.action = type;
            if (row) {
                this.brandManageEditData = row;
            } else {
                this.brandManageEditData = {};
            }
            this.dialogUploadAddToViewBrandVisible = true;
        },
        // 处理删除按钮
        handleDeleteBtn(row) {
            this.$confirm('确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            })
                .then(() => {
                    let dt = {
                        BrandID: row.BrandID
                    };
                    del(dt).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            center: true
                        });
                        this.init();
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        // 处理停用/启用按钮
        handleChangeStatus(row) {
            let actionName = row.Status.Status === 1 ? '停用' : '启用';
            let status = row.Status.Status == 1 ? 2 : 1;
            let dt = {
                BrandID: row.BrandID,
                Status: status
            };
            this.$confirm(`是否进行${actionName}操作？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            })
                .then(() => {
                    enable(dt).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            center: true
                        });
                        this.init();
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        // 处理分页
        handleSizeChange(size) {
            this.page.pageSize = size;
            this.page.page = 1;
            this.init();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            this.init();
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
    border-radius: 5px;
}
.brand-manage-page {
    box-sizing: border-box;
    padding: 15px;
    background-color: #fff;
    .brand-manage-header {
        &::after {
            display: block;
            clear: both;
            content: '';
            height: 0;
            visibility: hidden;
        }
        .header-left {
            float: left;
            .el-select,
            .el-input {
                margin-right: 20px;
            }
            .el-input {
                width: 200px;
            }
        }
        .header-right {
            float: right;
            span {
                position: relative;
                font-size: 18px;
            }
        }
    }
    .brand-manage-table {
        margin-top: 30px;
        .brand-logo,
        .brand-main-figure,
        .brand-sub-figure {
            ::v-deep .el-image__preview {
                display: none;
            }
        }
        .video {
            display: none;
        }
        .red-color {
            color: red;
        }
        .blue-color {
            color: #409eff;
        }
        .gray-color {
            color: #b2b2b2;
        }
    }
}
</style>