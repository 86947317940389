<template>
    <div class="upload-edit-page" v-loading="brandViewLoading">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item class="title" label="基本信息"></el-form-item>
            <el-form-item label="品牌名称" prop="brandName">
                <el-input size="small" maxlength="20" v-model="ruleForm.brandName" placeholder="请输入品牌名称"></el-input>
            </el-form-item>
            <el-form-item label="品牌介绍" prop="brandIntroduce">
                <el-input type="textarea" maxlength="300" :autosize="{ minRows: 4 }" v-model="ruleForm.brandIntroduce"
                    placeholder="请输入品牌介绍"></el-input>
            </el-form-item>
            <el-form-item label="置顶序号" prop="topMember">
                <el-input size="small" v-model="ruleForm.topMember" placeholder="请输入排序序号"></el-input>
            </el-form-item>
            <el-form-item label="关联账号" prop="associatAccount" v-if="action === 'view'">
                <el-input size="small" v-model="ruleForm.associatAccount" placeholder="请输入关联手机号"
                    :readonly="action === 'view' ? true : false"></el-input>
            </el-form-item>
            <el-form-item label="品牌LOGO" prop="brandLogo">
                <el-upload class="avatar-uploader" action="" :show-file-list="false" list-type="picture-card"
                    :auto-upload="false" :on-change="handleBrandLogoChange">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="picture-desc">
                    <p>尺寸为900x360px</p>
                    <p>格式为PNG/JPG，大小不超过10MB</p>
                </div>
            </el-form-item>
            <el-form-item label="品牌主图" prop="brandMainSrc">
                <el-upload class="avatar-uploader" action="" :show-file-list="false" list-type="picture-card"
                    :auto-upload="false" :on-change="handleBrandMainSrcChange">
                    <img v-if="imageUrl1" :src="imageUrl1" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="picture-desc">
                    <p>尺寸为1920*360px</p>
                    <p>格式为PNG/JPG，大小不超过10MB</p>
                </div>
            </el-form-item>
            <el-form-item label="品牌副图" prop="brandSubSrc">
                <el-upload class="avatar-uploader" action="" :show-file-list="false" list-type="picture-card"
                    :auto-upload="false" :on-change="handleBrandSubSrcChange">
                    <img v-if="imageUrl2" :src="imageUrl2" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="picture-desc">
                    <p>尺寸为440*265px</p>
                    <p>格式为PNG/JPG，大小不超过10MB</p>
                </div>
            </el-form-item>
            <el-form-item label="品牌介绍">
                <el-upload class="avatar-uploader" action="" :limit="5" :file-list="ruleForm.brandIntroduceSrc"
                    list-type="picture-card" :auto-upload="false" :on-change="handleBrandIntroduceSrcChange"
                    :on-exceed="handleExceed" :on-remove="handleBrandIntroduceRemove">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="picture-desc">
                    <p>尺寸为1920*1080px</p>
                    <p>格式为PNG/JPG，大小不超过10MB</p>
                </div>
            </el-form-item>
            <el-form-item label="品牌视频">
                <el-upload class="avatar-uploader" action="" :show-file-list="false" list-type="picture-card"
                    :auto-upload="false" :on-change="handleBrandVideoSrcChange">
                    <video class="avatar" style="width: 100%; height: 100%; object-fit: cover" v-if="videoUrl" controls
                        :src="videoUrl"></video>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="upload-desc">
                    <span class="el-upload__text">上传视频尺寸要求宽高>=1920*1080px</span>
                    <p class="el-upload__text">格式为：AVI，WMV，MPEG，3GP等主流视频格式</p>
                </div>
            </el-form-item>
            <el-form-item class="title" label="品牌记录" v-if="action === 'view' && ruleForm.brandLog.length > 0">
            </el-form-item>
            <el-form-item v-if="action === 'view' && ruleForm.brandLog.length > 0">
                <ul class="brand-record-list" :class="{ 'overflow-y-shaft': ruleForm.brandLog.length > 0 }">
                    <li class="brand-record-item" v-for="(item, index) in ruleForm.brandLog" :key="index">
                        <div>
                            <span>{{ item.brand_name }}</span>
                            <span>{{ item.operation }}</span>
                            <p style="display: inline-block; margin-left: 10px">
                                <span style="color: #3099ff" v-if="item.status === 0">{{
                                getBrandLogStatus(item.status)
                                }}</span>
                                <span style="color: #13ce66" v-else-if="item.status === 1">{{
                                getBrandLogStatus(item.status)
                                }}</span>
                                <span style="color: #ff6600" v-else>{{ getBrandLogStatus(item.status) }}</span>
                            </p>
                        </div>
                        <div>{{ item.update_time }}</div>
                    </li>
                </ul>
            </el-form-item>
            <el-form-item style="text-align: center" v-if="action == 'add'">
                <div class="confirm-upload" @click="handleConfirmUploadBtn('ruleForm')">上传</div>
            </el-form-item>
            <el-form-item v-if="action == 'view' && examineShow">
                <el-button style="margin-left: 27%" type="primary" @click="handleThroughApply(1)">通过申请</el-button>
                <el-button type="info" @click="handleThroughApply(4)">拒绝申请</el-button>
            </el-form-item>
            <el-form-item v-if="action == 'view' && !examineShow">
                <div class="confirm-upload" @click="handleConfirmUploadBtn('ruleForm')">确认修改</div>
            </el-form-item>
        </el-form>
    </div>
</template>


<script>
import { edit, examine, getMsg, save, deletePicture } from '@/common/api/operatsCenter/brandManage';
import { recordStatusList } from '@/utils/recordStatusList.js';

export default {
    name: 'brandUploadAddEdit',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        action: {
            type: String,
            default: ''
        },
        brandManageEditData: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        var validateName = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入品牌名称'));
            } else {
                // var pattern = /^[\u4E00-\u9FA5A-Za-z]+$/;
                // if (!pattern.test(value)) {
                //     callback(new Error('只能输入中文和英文字母'));

                // }
                callback();
            }
        };
        var validateInt = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入品牌介绍'));
            } else {
                // var pattern = /^[\u4E00-\u9FA5A-Za-z]+$/;
                // if (!pattern.test(value)) {
                //     callback(new Error('只能输入中文和英文字母'));
                // }
                callback();
            }
        };
        return {
            brandViewLoading: false,
            // 品牌记录状态列表
            brandLogStatusList: recordStatusList,
            ruleForm: {
                brandId: '',
                // 品牌名称
                brandName: '',
                // 品牌介绍
                brandIntroduce: '',
                // 置顶序号
                topMember: '',
                // 关联账号
                associatAccount: '',
                // 品牌Logo
                brandLogo: [],
                // 品牌主图
                brandMainSrc: [],
                // 品牌副图
                brandSubSrc: [],
                // 品牌介绍
                brandIntroduceSrc: [],
                // 品牌视频
                brandVideoSrc: [],
                // 品牌记录
                brandLog: []
            },
            imageUrl: '',
            imageUrl1: '',
            imageUrl2: '',
            videoUrl: '',
            fileList: [],
            examineShow: false,
            rules: {
                brandName: [
                    {
                        required: true,
                        validator: validateName,
                        trigger: 'blur'
                    }
                ],
                brandIntroduce: [
                    {
                        required: true,
                        validator: validateInt,
                        trigger: 'blur'
                    }
                ]
            }
        };
    },
    created() {
        if (this.brandManageEditData.b_id) {
            // console.log(this.brandManageEditData.b_id);
            // if (this.brandManageEditData.Status.Status == 3) {
            //     this.examineShow = true;
            // } else {
            //     this.examineShow = false;
            // }
            let dt = {
                BrandID: this.brandManageEditData.b_id
            };
            getMsg(dt).then(res => {
                if (res.code === 200) {
                    this.ruleForm = {
                        brandId: res.result.BrandID,
                        brandName: res.result.Name,
                        brandIntroduce: res.result.Details,
                        topMember: res.result.Sort,
                        associatAccount: res.result.Mobile,
                        brandLogo: res.result.Logo ? [{ url: res.result.Logo }] : [],
                        brandMainSrc: res.result.MasterMap ? [{ url: res.result.MasterMap }] : [],
                        brandSubSrc: res.result.AuxiliaryChart ? [{ url: res.result.AuxiliaryChart }] : [],
                        brandIntroduceSrc: [],
                        brandVideoSrc: res.result.Video ? [{ url: res.result.b_video }] : [],
                        brandLog: res.result.BrandLog.reverse() || []
                    };
                    if (res.result.b_introduce_picture) {
                        let arr = [];
                        for (var i = 0; i < res.result.b_introduce_picture.length; i++) {
                            let tmp = res.result.b_introduce_picture[i];
                            arr.push({ url: tmp });
                        }
                        this.ruleForm.brandIntroduceSrc = arr;
                    }
                    this.imageUrl = res.result.Logo;
                    this.imageUrl1 = res.result.MasterMap;
                    this.imageUrl2 = res.result.AuxiliaryChart;
                    this.videoUrl = res.result.b_video;
                }
            });
        }
    },
    methods: {
        getBrandLogStatus(status) {
            return this.brandLogStatusList.find(val => val.id === status)?.name;
        },
        // 处理上传按钮
        handleConfirmUploadBtn(ruleForm) {
            this.$refs[ruleForm].validate(valid => {
                if (valid) {
                    this.brandViewLoading = true;
                    let dt = new FormData();
                    if (this.ruleForm.brandId) {
                        dt.append('BrandID', this.ruleForm.brandId);
                    }
                    dt.append('Name', this.ruleForm.brandName);
                    dt.append('Describe', this.ruleForm.brandIntroduce);
                    dt.append('Sort', this.ruleForm.topMember);
                    dt.append('Mobile', this.ruleForm.associatAccount);
                    if (this.ruleForm.brandLogo.length) {
                        for (var i = 0; i < this.ruleForm.brandLogo.length; i++) {
                            if (this.ruleForm.brandLogo[i].raw) {
                                dt.append('Logo', this.ruleForm.brandLogo[i].raw);
                            }
                        }
                    }
                    if (this.ruleForm.brandMainSrc.length) {
                        for (var j = 0; j < this.ruleForm.brandMainSrc.length; j++) {
                            if (this.ruleForm.brandMainSrc[j].raw) {
                                dt.append('MasterMap', this.ruleForm.brandMainSrc[j].raw);
                            }
                        }
                    }
                    if (this.ruleForm.brandSubSrc.length) {
                        for (var z = 0; z < this.ruleForm.brandSubSrc.length; z++) {
                            if (this.ruleForm.brandSubSrc[z].raw) {
                                dt.append('AuxiliaryChart', this.ruleForm.brandSubSrc[z].raw);
                            }
                        }
                    }
                    if (this.ruleForm.brandIntroduceSrc.length) {
                        for (var m = 0; m < this.ruleForm.brandIntroduceSrc.length; m++) {
                            if (this.ruleForm.brandIntroduceSrc[m].raw) {
                                dt.append('IntroducePicture[]', this.ruleForm.brandIntroduceSrc[m].raw);
                            }
                        }
                    }
                    if (this.ruleForm.brandVideoSrc.length) {
                        for (var n = 0; n < this.ruleForm.brandVideoSrc.length; n++) {
                            if (this.ruleForm.brandVideoSrc[n].raw) {
                                dt.append('Video', this.ruleForm.brandVideoSrc[n].raw);
                            }
                        }
                    }
                    for (let [a, b] of dt.entries()) {
                        // console.log(a + '=>' + b);
                    }
                    if (this.ruleForm.brandId) {
                        edit(dt).then(res => {
                            if (res.code === 200) {
                                this.$message.success(res.msg);
                                this.$refs['ruleForm'].resetFields();
                                this.imageUrl = '';
                                this.imageUrl1 = '';
                                this.imageUrl2 = '';
                                this.videoUrl = '';
                                this.brandViewLoading = false;
                                this.$emit('update:show', false);
                            }
                        });
                    } else {
                        save(dt).then(res => {
                            if (res.code === 200) {
                                this.$message.success(res.msg);
                                this.$refs['ruleForm'].resetFields();
                                this.imageUrl = '';
                                this.imageUrl1 = '';
                                this.imageUrl2 = '';
                                this.videoUrl = '';
                                this.brandViewLoading = false;
                                this.$emit('update:show', false);
                            }
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        // 处理通过申请按钮
        handleThroughApply(type) {
            let tip = type == 1 ? '通过' : '驳回';
            this.$confirm('确认' + tip + '?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            })
                .then(() => {
                    this.brandViewLoading = true;
                    let dt = {
                        BrandID: this.brandManageEditData.BrandID,
                        Status: type
                    };
                    examine(dt).then(res => {
                        if (res.code === 200) {
                            this.$message({
                                type: 'success',
                                message: res.msg,
                                center: true
                            });
                            this.brandViewLoading = false;
                            this.$emit('update:show', false);
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        // 处理品牌logo相关事件
        handleBrandLogoChange(file) {
            let url = URL.createObjectURL(file.raw);
            const isType = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isType) {
                this.$message.error('上传图片只能是 jpg/png 格式!');
                return false;
            }
            if (!isLt10M) {
                this.$message.error('上传图片大小不能超过 10MB!');
                return false;
            }
            new Promise(function (resolve, reject) {
                let width = 900;
                let height = 360;
                let img = new Image();
                img.onload = function () {
                    let valid = img.width === width && img.height === height;
                    valid ? resolve() : reject();
                };
                img.src = url;
            }).then(
                () => {
                    this.imageUrl = file.url;
                    this.ruleForm.brandLogo[0] = file;
                    return file;
                },
                () => {
                    this.$message.error('上传图片尺寸只能是 900*360px !');
                    return Promise.reject();
                }
            );
        },
        // 处理品牌主图相关事件
        handleBrandMainSrcChange(file) {
            let url = URL.createObjectURL(file.raw);
            const isType = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isType) {
                this.$message.error('上传图片只能是 jpg/png 格式!');
                return false;
            }
            if (!isLt10M) {
                this.$message.error('上传图片大小不能超过 10MB!');
                return false;
            }
            new Promise(function (resolve, reject) {
                let width = 1920;
                let height = 360;
                let img = new Image();
                img.onload = function () {
                    let valid = img.width === width && img.height === height;
                    valid ? resolve() : reject();
                };
                img.src = url;
            }).then(
                () => {
                    this.imageUrl1 = file.url;
                    this.ruleForm.brandMainSrc.push(file);
                    return file;
                },
                () => {
                    this.$message.error('上传图片尺寸只能是 1920*1080px !');
                    return false;
                }
            );
        },
        // 处理品牌l副图相关事件
        handleBrandSubSrcChange(file) {
            let url = URL.createObjectURL(file.raw);
            const isType = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isType) {
                this.$message.error('上传图片只能是 jpg/png 格式!');
                return false;
            }
            if (!isLt10M) {
                this.$message.error('上传图片大小不能超过 10MB!');
                return false;
            }
            new Promise(function (resolve, reject) {
                let width = 440;
                let height = 265;
                let img = new Image();
                img.onload = function () {
                    let valid = img.width === width && img.height === height;
                    valid ? resolve() : reject();
                };
                img.src = url;
            }).then(
                () => {
                    this.ruleForm.brandSubSrc.push(file);
                    this.imageUrl2 = file.url;
                    return file;
                },
                () => {
                    this.$message.error('上传图片尺寸只能是 440*265px !');
                    return false;
                }
            );
        },
        // 处理品牌介绍相关事件
        handleBrandIntroduceSrcChange(file, fileList) {
            let url = URL.createObjectURL(file.raw);
            const isType = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isType) {
                this.$message.error('上传图片只能是 jpg/png 格式!');
                this.ruleForm.brandIntroduceSrc.pop();
                return false;
            }
            if (!isLt10M) {
                this.$message.error('上传图片大小不能超过 10MB!');
                this.ruleForm.brandIntroduceSrc.pop();
                return false;
            }
            new Promise(function (resolve, reject) {
                let width = 1920;
                let height = 1080;
                let img = new Image();
                img.onload = function () {
                    let valid = img.width === width && img.height <= height;
                    valid ? resolve() : reject();
                };
                img.src = url;
            }).then(
                () => {
                    this.ruleForm.brandIntroduceSrc.push(file);
                    return file;
                },
                () => {
                    this.$message.error('上传图片尺寸尺寸为1920*1080px');
                    fileList.pop();
                    return Promise.reject();
                }
            );
        },

        // 处理品牌视频相关事件
        handleBrandVideoSrcChange(file) {
            const isVideo =
                file.raw.type === 'video/mp4' ||
                file.raw.type === 'video/mpeg' ||
                file.raw.type === 'video/flv' ||
                file.raw.type === 'video/avi' ||
                file.raw.type === 'video/wmv' ||
                file.raw.type === 'video/rmvb' ||
                file.raw.type === 'video/3gp' ||
                file.raw.type === 'video/mov';
            const isLt500M = file.raw.size / 1024 / 1024 < 500;
            if (!isVideo) {
                this.$message.warning('请上传正确格式的视频！');
                return false;
            } else if (!isLt500M) {
                this.$message.warning('上传视频文件大小不能超过 500MB!');
                return false;
            }
            new Promise((resolve, reject) => {
                let _URL = window.URL || window.webkitURL;
                let videoElement = document.createElement('video');
                videoElement.addEventListener('loadedmetadata', function () {
                    // 判断上传视频的宽高是否为16:9分辨率的
                    // 位操作符 NOT
                    let valid =
                        (~~(parseInt(videoElement.videoWidth) / 16) * 9 === parseInt(videoElement.videoHeight) &&
                            videoElement.videoWidth === 3840) ||
                        videoElement.videoWidth === 2560 ||
                        videoElement.videoWidth === 1980 ||
                        videoElement.videoHeight === 2160 ||
                        videoElement.videoHeight === 1440 ||
                        videoElement.videoHeight === 1080;
                    valid ? resolve() : reject();
                });
                videoElement.src = _URL.createObjectURL(file.raw);
            }).then(
                () => {
                    this.ruleForm.brandVideoSrc.push(file);
                    this.videoUrl = file.url;
                    return file;
                },
                () => {
                    this.$message.error('请上传正确比例的视频');
                    return false;
                }
            );
        },
        // 品牌介绍图片删除
        handleBrandIntroduceRemove(file, fileList) {
            // console.log(file.url, fileList);
            let params = {
                BrandID: this.brandManageEditData.BrandID,
                PicturePath: file.url
            };
            deletePicture(params)
                .then(res => {
                    let { code, msg } = res;
                    if (code === 200) {
                        this.$message({
                            type: 'success',
                            message: `${msg}`,
                            center: true
                        });
                        this.ruleForm.brandIntroduceSrc = fileList;
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 上传图片公用函数
        handleExceed(files, fileList) {
            this.$message.error(
                `当前限制选择5个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
                } 个文件`
            );
        }
    }
};
</script>

<style lang="scss" scoped>
.upload-edit-page {
    padding: 0 20px;

    .title {
        ::v-deep .el-form-item__label {
            font-size: 18px;
            color: #000;
        }
    }

    .el-form-item:nth-child(11) {
        margin-bottom: 0 !important;
    }

    .el-input {
        width: 240px;
    }

    .avatar-uploader ::v-deep .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 148px;
        height: 148px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    ::v-deep .el-icon-plus:before {
        position: relative;
        top: -16px;
    }

    .confirm-upload {
        width: 250px;
        margin: 50px 0 10px 20%;
        background: #1790ff;
        color: #fff;
        line-height: 40px;
        text-align: center;
        border-radius: 10px;
        cursor: pointer;
    }

    .overflow-y-shaft {
        height: 400px;
        overflow-y: scroll;
    }

    // ::v-deep .el-image__preview {
    //     display: none;
    // }
}
</style>