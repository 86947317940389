<template>
    <!-- 品牌信息表 -->
    <div class="enterprise-info-page">
        <div class="enterprise-info-content" v-loading="mainLoading">
            <div class="rightcontent">
                <div class="right-content">
                    <!-- <div class="left-content" style="display: inline-block">
                        <div class="enterprise-introduction">
                            <div class="enterprise-logo">
                                <img
                                    v-if="enterpriseInfoForm.logo"
                                    style="border: 1px dashed #bbb"
                                    :src="enterpriseInfoForm.logo.url"
                                    alt="请上传品牌logo"
                                />
                                <p v-else>未上传品牌logo</p>
                                <span v-if="enterpriseInfoForm.logo.isChange" class="colorRed">已修改</span>
                            </div>
                            <h1 class="enterprise-name">{{ enterpriseInfoForm.enterpriseName.name }}</h1>
                            <span v-if="enterpriseInfoForm.enterpriseName.isChange" class="colorRed">已修改</span>
                        </div>
                    </div> -->
                    <span style="float: right; vertical-align: top">
                        <el-button type="mini" icon="el-icon-close" @click="off"></el-button>
                    </span>

                    <div>
                        <div class="enterprise-info" style="padding-top: 25px">
                            <div class="top-title" style="padding-bottom: 20px">
                                <h3>品牌信息</h3>
                                <!-- <el-button-group>-->
                                <!--   <el-button type="mini" icon="el-icon-close" @click="off"></el-button>-->
                                <!-- </el-button-group>-->
                            </div>
                            <el-form ref="enterpriseInfoForm" :model="enterpriseInfoForm" :rules="rules"
                                label-width="100px" label-position="left" size="mini" class="enterprise-info-edit">
                                <el-form-item label="品牌名称：" prop="enterpriseName">
                                    <div style="width: 420px">{{ enterpriseInfoForm.enterpriseName.name }}</div>
                                    <span v-if="enterpriseInfoForm.enterpriseName.isChange" class="colorRed">已修改</span>
                                </el-form-item>
                                <el-form-item label="品牌介绍：" prop="enterpriseIntroduce">
                                    <div>{{ enterpriseInfoForm.enterpriseIntroduce.content }}</div>
                                    <span v-if="enterpriseInfoForm.enterpriseIntroduce.isChange"
                                        class="colorRed">已修改</span>
                                </el-form-item>
                            </el-form>
                            <!-- <div class="login-btn" @click="loginout">退出登录</div> -->
                        </div>
                    </div>

                    <div class="enterprise-main-picture">
                        <div class="top-title">
                            <h3>品牌LOGO</h3>
                        </div>
                        <el-image v-if="enterpriseInfoForm.logo.url"
                            style="width: 400px; height: 160px; border-radius: 5px; border: 1px dashed #909693"
                            :src="enterpriseInfoForm.logo.url" :preview-src-list="[enterpriseInfoForm.logo.url]">
                        </el-image>
                        <p v-else style="
                                width: 360px;
                                height: 180px;
                                border-radius: 5px;
                                border: 1px dashed #909693;
                                line-height: 180px;
                                text-align: center;
                            ">
                            未上传品牌LOGO
                        </p>
                        <span v-if="enterprisePictureForm.enterpriseMainPicture.isChange" class="colorRed">已修改</span>
                    </div>

                    <div class="enterprise-main-picture">
                        <div class="top-title">
                            <h3>品牌主图</h3>
                        </div>
                        <el-image v-if="enterprisePictureForm.enterpriseMainPicture.url"
                            style="width: 360px; height: 180px; border-radius: 5px; border: 1px dashed #909693"
                            :src="enterprisePictureForm.enterpriseMainPicture.url"
                            :preview-src-list="[enterprisePictureForm.enterpriseMainPicture.url]">
                        </el-image>
                        <p v-else style="
                                width: 360px;
                                height: 180px;
                                border-radius: 5px;
                                border: 1px dashed #909693;
                                line-height: 180px;
                                text-align: center;
                            ">
                            未上传品牌主图
                        </p>
                        <span v-if="enterprisePictureForm.enterpriseMainPicture.isChange" class="colorRed">已修改</span>
                    </div>
                    <div class="enterprise-sub-picture">
                        <h3>品牌副图</h3>
                        <el-image v-if="enterprisePictureForm.enterpriseSubPicture.url"
                            style="width: 360px; height: 180px; border-radius: 5px; border: 1px dashed #909693"
                            :src="enterprisePictureForm.enterpriseSubPicture.url"
                            :preview-src-list="[enterprisePictureForm.enterpriseSubPicture.url]">
                        </el-image>
                        <p v-else style="
                                width: 360px;
                                height: 180px;
                                border-radius: 5px;
                                border: 1px dashed #909693;
                                line-height: 180px;
                                text-align: center;
                            ">
                            未上传品牌副图
                        </p>
                        <span v-if="enterprisePictureForm.enterpriseSubPicture.isChange" class="colorRed">已修改</span>
                    </div>
                    <div class="enterprise-video">
                        <h3>品牌视频</h3>
                        <video style="width: 40%; object-fit: cover" v-if="enterprisePictureForm.enterpriseVideoUrl.url"
                            controls :src="enterprisePictureForm.enterpriseVideoUrl.url"></video>
                        <p v-else style="
                                width: 360px;
                                height: 180px;
                                border-radius: 5px;
                                border: 1px dashed #909693;
                                line-height: 180px;
                                text-align: center;
                            ">
                            未上传品牌视频
                        </p>
                        <span v-if="enterprisePictureForm.enterpriseVideoUrl.isChange" class="colorRed">已修改</span>
                    </div>
                    <div class="enterprise-introduce-picture">
                        <h3>品牌介绍（轮播图）</h3>
                        <div v-for="(item, index) in enterprisePictureForm.enterpriseIntroducePictureList" :key="index">
                            <el-image
                                style="width: 360px; height: 180px; border-radius: 5px; border: 1px dashed #909693"
                                :src="item.url" :preview-src-list="[item.url]">
                            </el-image>
                            <span v-if="item.isChange" class="colorRed">已修改</span>
                        </div>
                        <p v-if="!enterprisePictureForm.enterpriseIntroducePictureList.length" style="
                                width: 360px;
                                height: 180px;
                                border-radius: 5px;
                                border: 1px dashed #909693;
                                line-height: 180px;
                                text-align: center;
                            ">
                            未上传品牌介绍
                        </p>
                    </div>
                    <div style="margin-top: 25px">
                        <el-button size="medium" type="primary" @click="handleAuditThroughBtn()">通过</el-button>
                        <el-button size="medium" @click="handleAuditRejectBtn()">驳回</el-button>
                    </div>
                </div>
            </div>
        </div>

        <!-- 更换LOGO弹窗 -->
        <!--    <dialogFrame-->
        <!--        width="600px"-->
        <!--        :show="dialogModifyLogoDialogVisible"-->
        <!--        :title="'更换LOGO'"-->
        <!--        :footer="false"-->
        <!--        @showDialog="showDialog"-->
        <!--    >-->
        <!--      <modifyLogoDialog-->
        <!--          v-if="dialogModifyLogoDialogVisible"-->
        <!--          :modifyLogoData="modifyLogoData"-->
        <!--          @closeDialog="closeDialog"-->
        <!--      ></modifyLogoDialog>-->
        <!--    </dialogFrame>-->
        <!-- 修改手机号弹窗 -->
        <!-- <dialogFrame
        width="560px"
        :show="dialogModifyLoginPhoneDialogVisible"
        :title="'修改手机号'"
        :footer="false"
        @showDialog="showDialog"
    >
        <modifyLoginPhoneDialog
            v-if="dialogModifyLoginPhoneDialogVisible"
            :modifyLoginPhoneData="modifyLoginPhoneData"
        ></modifyLoginPhoneDialog>
    </dialogFrame> -->
        <!-- 修改密码弹窗 -->
        <!-- <dialogFrame
        width="560px"
        :show="dialogModifyLoginPasswordDialogVisible"
        :title="'修改密码'"
        :footer="false"
        @showDialog="showDialog"
    >
        <modifyLoginPasswordDialog v-if="dialogModifyLoginPasswordDialogVisible"></modifyLoginPasswordDialog>
    </dialogFrame> -->
    </div>
</template>

<script>
// import dialogFrame from '@/components/common/dialog/dialog-frame.vue';
// import modifyLogoDialog from '@/views/companyManage/companyInfo/components/modifyLogoDialog.vue';
import { globalDesensitized } from '@/utils/utils.js';
import { getExamineinfo, brandAuditAction } from '@/common/api/operatsCenter/brandManage.js';
// 详情
import jsonTool from '@/utils/jsonTool.js';
export default {
    name: 'enterpriseInfoPage',
    components: {
        // dialogFrame,
        // modifyLoginPhoneDialog,
        // modifyLoginPasswordDialog,
    },
    props: {
        brandAuditData: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        const validateEnterpriseName = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('品牌名称不能为空'));
            } else {
                callback();
            }
        };
        const validateLoginPhone = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('登录手机号不能为空'));
            } else {
                callback();
            }
        };
        const validateLoginPassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('登录密码不能为空'));
            } else {
                callback();
            }
        };
        const validateEnterpriseIntroduce = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('品牌介绍不能为空'));
            } else {
                callback();
            }
        };
        return {
            // 是否显示品牌主图图片裁剪
            isShowMainVueCropperDialog: false,
            // 是否显示品牌附图图片裁剪
            isShowSubVueCropperDialog: false,
            // 是否显示品牌介绍图片裁剪
            isShowIntroduceVueCropperDialog: false,
            // 保存图片基本信息
            fileType: {},
            // 裁剪图片配置项
            option: {
                img: '', // 裁剪图片的地址
                outputSize: 1, // 裁剪生成图片的质量
                outputType: 'png', // 裁剪生成图片的格式(jpeg || png ||webp)
                info: true, // 裁剪框的大小信息
                canScale: true, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth: '', // 默认生成截图框宽度
                autoCropHeight: '', // 默认生成截图框高度
                fixed: false, // 是否开启截图框宽高固定比例
                fixedNumber: [1, 1], // 截图框的宽高比例,设置了autoCropWidth、autoCropHeight后不生效
                full: false, // 是否输出原图比例的截图
                fixedBox: true, // 固定截图框大小
                canMove: true, // 上传图片是否可以移动
                canMoveBox: true, // 截图框能否拖动
                original: false, // 上传图片按照原始比例渲染
                centerBox: false, // 截图框是否被限制在图片里面
                high: true, // 是否按照设备的dpr 输出等比例图片
                infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
                maxImgSize: 3840, // 限制图片最大宽度和高度
                enlarge: 2 // 图片根据截图框输出比例倍数
            },
            // 保存上传图片
            fileList: [],
            // 查看案例图片
            imageUrl: '',
            // 查看案例视频
            videoUrl: '',
            // 是否显示品牌视频案例
            dialogenterpriseVideoVisible: false,
            newly: '',
            enterpriseInfoForm: {
                // logo图片
                logo: '',
                // 品牌名称
                enterpriseName: '',
                // 线上构件
                onlineMember: '',
                // 累计曝光量
                cumulativeExposure: '',
                // 累计获取线索
                cumulativeClues: '',
                // 登录手机号
                loginPhone: '',
                // 登录密码
                loginPassword: '',
                // 品牌介绍
                enterpriseIntroduce: ''
            },
            enterprisePictureForm: {
                // 品牌主图
                enterpriseMainPicture: '',
                // 查看案例图片
                enterpriseMainSamplePicture: '',
                // 品牌副图
                enterpriseSubPicture: '',
                // 查看案例图片
                enterpriseSubSamplePicture: '',
                // 品牌视频
                enterpriseVideoUrl: '',
                // 查看案例视频
                enterpriseVideoSamplePicture: '',
                // 品牌介绍
                enterpriseIntroducePicture: [],
                enterpriseIntroducePictureList: [{ imgurl: '' }],
                // 查看案例图片
                enterpriseIntroduceSamplePicture: ''
            },
            rules: {
                enterpriseName: [{ validator: validateEnterpriseName, trigger: 'blur' }],
                loginPhone: [
                    {
                        validator: validateLoginPhone,
                        trigger: 'blur'
                    },
                    {
                        pattern: /^1[3456789][0-9]{9}$/,
                        message: '手机号格式不正确',
                        trigger: 'blur'
                    }
                ],
                loginPassword: [
                    {
                        validator: validateLoginPassword,
                        trigger: 'blur'
                    },
                    {
                        pattern: /^[A-Za-z0-9]+$/,
                        message: '密码只能是英文和数字组成',
                        trigger: 'blur'
                    }
                ],
                enterpriseIntroduce: [{ validator: validateEnterpriseIntroduce, trigger: 'blur' }]
            },
            // 是否修改品牌信息
            isModify: false,
            // 修改手机弹窗
            dialogModifyLoginPhoneDialogVisible: false,
            modifyLoginPhoneData: {},
            // 修改密码弹窗
            dialogModifyLoginPasswordDialogVisible: false,
            // modifyLoginPasswordData: {}
            // 更换LOGO弹窗
            dialogModifyLogoDialogVisible: false,
            modifyLogoData: {},
            mainLoading: false,
            mianPictureLoaing: false,
            subPictureLoading: false,
            videoLoading: false,
            introduceLoading: false
        };
    },
    mounted() {
        this.queryEnterpriseInfoData();
    },
    methods: {
        // 处理驳回按钮
        handleAuditRejectBtn() {
            this.$prompt('驳回理由', 'Tip', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^.+$/,
                inputErrorMessage: '请输入驳回理由'
            }).then(({ value }) => {
                let params = {
                    BrandID: this.brandAuditData.b_id,
                    Status: 3,
                    rejectReason: value
                };
                brandAuditAction(params).then(res => {
                    let { code, msg } = res;
                    if (code === 200) {
                        this.$message({
                            type: 'success',
                            message: `${msg}`,
                            center: true
                        });
                        this.$emit('closeAuditDialog', false);
                    }
                });
            });
        },
        handleAuditThroughBtn() {
            let params = {
                BrandID: this.brandAuditData.b_id,
                Status: 2,
                rejectReason: ''
            };
            this.$confirm(`确认通过?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            }).then(() => {
                brandAuditAction(params).then(res => {
                    let { code, msg } = res;
                    if (code === 200) {
                        this.$message({
                            type: 'success',
                            message: `${msg}`,
                            center: true
                        });
                        this.$emit('closeAuditDialog', false);
                    }
                });
            });
        },
        off() {
            this.$emit('closeAuditDialog');
        },
        showDialog(val) {
            this.dialogModifyLogoDialogVisible = val;
            this.dialogModifyLoginPhoneDialogVisible = val;
            this.dialogModifyLoginPasswordDialogVisible = val;
        },
        closeDialog(val) {
            this.dialogModifyLogoDialogVisible = val;
            this.queryEnterpriseInfoData();
        },
        // 分割手机号
        segmentationPhone(phone) {
            let result = phone
                ? phone.replace(/\s/g, '').replace(/(\d{3})(\d{0,4})(\d{0,4})/, '$1 $2 $3')
                : phone.trim();
            return result;
        },
        // 查询品牌信息数据
        queryEnterpriseInfoData() {
            this.mainLoading = true;
            let params = {
                id: this.brandAuditData.b_id
            };
            getExamineinfo(params)
                .then(res => {
                    let { code, result } = res;
                    if (code === 200) {
                        this.mainLoading = false;
                        let newResult = jsonTool(result);

                        this.enterpriseInfoForm = {
                            // logo图片
                            logo: newResult.bLogo,
                            // 品牌名称
                            enterpriseName: newResult.bName,
                            // 品牌介绍
                            enterpriseIntroduce: newResult.bDescribe
                        };
                        this.enterprisePictureForm = {
                            // 品牌主图
                            enterpriseMainPicture: newResult.bMasterMap,
                            // 查看案例图片
                            enterpriseMainSamplePicture: '',
                            // 品牌副图
                            enterpriseSubPicture: newResult.bAuxiliaryChart,
                            // 查看案例图片
                            enterpriseSubSamplePicture: '',
                            // 品牌视频
                            enterpriseVideoUrl: newResult.bVideo,
                            // 查看案例视频
                            enterpriseVideoSamplePicture: '',
                            // 品牌介绍
                            enterpriseIntroducePicture: [],
                            enterpriseIntroducePictureList: newResult.bIntroducePicture,
                            // 查看案例图片
                            enterpriseIntroduceSamplePicture: ''

                            // enterpriseMainPicture,
                            // enterpriseSubPicture,
                            // enterpriseVideoUrl,
                            // // 查看案例图片路径写死
                            // enterpriseMainSamplePicture:
                            //     'https://mx-component.oss-cn-hangzhou.aliyuncs.com/case/brand-main-img.png',
                            // enterpriseSubSamplePicture:
                            //     'https://mx-component.oss-cn-hangzhou.aliyuncs.com/case/brand-assistant-img.png',
                            // enterpriseVideoSamplePicture:
                            //     'https://mx-component.oss-cn-hangzhou.aliyuncs.com/case/brand-video.png',
                            // enterpriseIntroduceSamplePicture:
                            //     'https://mx-component.oss-cn-hangzhou.aliyuncs.com/case/brand-introduce-img.png',
                            // // 品牌介绍图片
                            // enterpriseIntroducePictureList: enterpriseIntroducePictureList
                        };
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 密码全部脱敏处理
        passwordDesensitization(value) {
            let result = globalDesensitized(value);
            return result;
        },
        // 处理编辑按钮
        handleEditBtn() {
            this.enterpriseInfoForm.loginPassword = '';
            this.isModify = true;
        },

        // // 处理更换logo按钮
        // handleModifyLogobtn() {
        //   this.dialogModifyLogoDialogVisible = true;
        //   let { logo: imgUrl } = this.enterpriseInfoForm;
        //   this.modifyLogoData = {
        //     imgUrl,
        //     b_id: this.id
        //   };
        // },
        // // 处理修改手机号按钮
        // handleModifyLoginPhonebtn() {
        //     this.dialogModifyLoginPhoneDialogVisible = true;
        //     this.modifyLoginPhoneData = {
        //         oldPhone: this.enterpriseInfoForm.loginPhone
        //     };
        // },
        // // 处理修改密码按钮
        // handleModifyLoginPasswordBtn() {
        //     this.dialogModifyLoginPasswordDialogVisible = true;
        // },
        // 处理查看案例按钮
        handleLookCasebtn(ref) {
            if (
                this.enterprisePictureForm.enterpriseMainSamplePicture ||
                this.enterprisePictureForm.enterpriseSubSamplePicture ||
                this.enterprisePictureForm.enterpriseVideoSamplePicture
            ) {
                this.$refs[ref].showViewer = true;
            }
        },
        // 处理查看视频案例按钮
        handleLookVideoCasebtn() {
            if (this.enterprisePictureForm.enterpriseVideoSamplePicture) {
                this.dialogenterpriseVideoVisible = true;
            }
        },
        // 处理查看品牌介绍案例按钮
        handleLookEnterpriseIntroduceCasebtn(ref) {
            if (this.enterprisePictureForm.enterpriseIntroduceSamplePicture) {
                this.$refs[ref][0].showViewer = true;
            }
        },
        // 处理品牌主图上传
        uploadEnterpriseMainPicture(file) {
            const isType = file.raw.type === 'image/jpeg' || 'image/png';
            const isLt10M = file.raw.size / 1024 / 1024 < 10;
            if (!isType) {
                this.$message.error('请上传jpg/png格式图片!');
                return;
            } else if (!isLt10M) {
                this.$message.error('上传图片大小不超过10MB');
                return;
            } else {
                // this.checkImgSize(file.raw, 1920, 360, 'enterpriseMainPictureRef').then(data => {
                //     if (data) {
                // console.log('data: ', file.raw);
                // 保存图片基本信息
                this.fileType = file.raw;
                // 打开裁剪弹窗
                let reader = new FileReader(); // 创建文件读取对象
                reader.onload = e => {
                    let imgUrl;
                    if (typeof e.target.result === 'object') {
                        // 把Array Buffer转化为blob 如果是base64不需要
                        imgUrl = window.URL.createObjectURL(new Blob([e.target.result]));
                    } else {
                        imgUrl = e.target.result;
                    }
                    this.option.img = imgUrl;
                    this.isShowMainVueCropperDialog = true;
                };
                // 转化为blob
                reader.readAsArrayBuffer(file.raw);
                // 重新设置option属性
                this.option.autoCropWidth = 960;
                this.option.autoCropHeight = 180;
                //     }
                // });
            }
        },
        // 处理品牌附图上传
        uploadEnterpriseSubPicture(file) {
            const isType = file.raw.type === 'image/jpeg' || 'image/png';
            const isLt10M = file.raw.size / 1024 / 1024 < 10;
            if (!isType) {
                this.$message.error('请上传jpg/png格式图片!');
                return;
            } else if (!isLt10M) {
                this.$message.error('上传图片大小不超过10MB');
                return;
            } else {
                // this.checkImgSize(file.raw, 440, 265, 'enterpriseSubPictureRef').then(data => {
                //     if (data) {
                // 保存图片基本信息
                this.fileType = file.raw;
                // 打开裁剪弹窗
                let reader = new FileReader(); // 创建文件读取对象
                reader.onload = e => {
                    let imgUrl;
                    if (typeof e.target.result === 'object') {
                        // 把Array Buffer转化为blob 如果是base64不需要
                        imgUrl = window.URL.createObjectURL(new Blob([e.target.result]));
                    } else {
                        imgUrl = e.target.result;
                    }
                    this.option.img = imgUrl;
                    this.isShowSubVueCropperDialog = true;
                };
                // 转化为blob
                reader.readAsArrayBuffer(file.raw);
                // 重新设置option属性
                this.option.autoCropWidth = 440;
                this.option.autoCropHeight = 265;
                this.option.enlarge = 1;
                //     }
                // });
            }
        },
        // 预览品牌主图/附图
        handleEnterpriseOntologyPicturePreview(ref) {
            if (this.enterprisePictureForm.enterpriseSubPicture || this.enterprisePictureForm.enterpriseMainPicture) {
                this.$refs[ref].showViewer = true;
            }
        },
        // 品牌视频
        uploadEnterpriseVideo(file) {
            const isVideo =
                file.file.type === 'video/mp4' ||
                file.file.type === 'video/mpeg' ||
                file.file.type === 'video/flv' ||
                file.file.type === 'video/avi' ||
                file.file.type === 'video/wmv' ||
                file.file.type === 'video/rmvb' ||
                file.file.type === 'video/3gp' ||
                file.file.type === 'video/mov';
            const isLt500M = file.file.size / 1024 / 1024 < 500;
            if (!isVideo) {
                this.$message.warning('请上传正确格式的视频！');
                return false;
            } else if (!isLt500M) {
                this.$message.warning('上传视频文件大小不能超过 500MB!');
                return false;
            } else {
                this.getVideoInfo(file).then(data => {
                    if (!data) {
                        return;
                    } else {
                        this.videoLoading = true;
                        this.fileList = data.file;
                        let formParams = new FormData();
                        formParams.append('b_id', this.id);
                        formParams.append('files', this.fileList);
                        formParams.append('type', 'b_video');
                        this.$message({
                            type: 'success',
                            message: '视频上传中，请稍等！',
                            center: true,
                            duration: 5000
                        });
                        // uploadEnterprisePictureAction(formParams)
                        // .then(res => {
                        //   let { code, msg } = res;
                        //   if (code === 200) {
                        //     this.videoLoading = false;
                        //     this.$message({
                        //       type: 'success',
                        //       message: `${msg}`,
                        //       center: true
                        //     });
                        //     this.$refs.enterpriseVideoRef.clearFiles();
                        //     this.queryEnterpriseInfoData();
                        //   } else {
                        //     this.$refs.enterpriseVideoRef.clearFiles();
                        //   }
                        // })
                        // .catch(err => {
                        //   console.log(err);
                        // });
                    }
                });
            }
        },
        // 处理品牌介绍上传
        uploadEnterpriseIntroducePicture(obj, file) {
            const isType = file.raw.type === 'image/jpeg' || 'image/png';
            const isLt10M = file.raw.size / 1024 / 1024 < 10;
            if (!isType) {
                this.$message.error('请上传jpg/png格式图片!');
                return;
            } else if (!isLt10M) {
                this.$message.error('上传图片大小不超过10MB');
                return;
            } else {
                // this.checkImgSize(file.raw, 1920, 1080, obj.refDom).then(data => {
                //     if (data) {
                // 保存图片基本信息
                this.fileType = file.raw;
                // 打开裁剪弹窗
                let reader = new FileReader(); // 创建文件读取对象
                reader.onload = e => {
                    let imgUrl;
                    if (typeof e.target.result === 'object') {
                        // 把Array Buffer转化为blob 如果是base64不需要
                        imgUrl = window.URL.createObjectURL(new Blob([e.target.result]));
                    } else {
                        imgUrl = e.target.result;
                    }
                    this.option.img = imgUrl;
                    this.isShowIntroduceVueCropperDialog = true;
                };
                // 转化为blob
                reader.readAsArrayBuffer(file.raw);
                // 重新设置option属性
                this.option.autoCropWidth = 960;
                this.option.autoCropHeight = 540;
                //     }
                // });
            }
        },
        // 处理删除品牌介绍图片
        handleDeleteEnterpriseIntroducePicture(data, index) {
            if (data.imgurl) {
                let params = {
                    b_id: this.id,
                    PicturePath: data.imgurl
                };
                // deletePictureAction(params)
                //     .then(res => {
                //       let { code, msg } = res;
                //       if (code === 200) {
                //         this.$message({
                //           type: 'success',
                //           message: `${msg}`,
                //           center: true
                //         });
                //         this.enterprisePictureForm.enterpriseIntroducePictureList.splice(index, 1);
                //       }
                //     })
                //     .catch(err => {
                //       console.log(err);
                //     });
            } else {
                this.enterprisePictureForm.enterpriseIntroducePictureList.splice(index, 1);
            }
        },
        // 预览品牌介绍
        handleEnterpriseIntroducePicturePreview(ref, data) {
            if (data.imgurl) {
                this.$refs[ref][0].showViewer = true;
            }
        },
        // 处理裁剪图片确定按钮
        handleEnterprisePictureSaveCutOut(params, refDom) {
            let refElement = params == 'b_introduce_picture' ? this.$refs.imageCropper[0] : this.$refs.imageCropper;
            refElement.getCropBlob(data => {
                let files = new File([data], this.fileType.name, {
                    type: data.type
                });
                let formParams = new FormData();
                if (params == 'b_introduce_picture') {
                    formParams.append('files', files);
                } else {
                    formParams.append('files', files);
                }
                formParams.append('b_id', this.id);
                formParams.append('type', params);
                // uploadEnterprisePictureAction(formParams)
                //     .then(res => {
                //       let { code, msg } = res;
                //       if (code === 200) {
                //         this.$message({
                //           type: 'success',
                //           message: `${msg}`,
                //           center: true
                //         });
                //         // 关闭裁剪弹窗
                //         if (refDom == 'enterpriseMainPictureRef') {
                //           this.isShowMainVueCropperDialog = false;
                //         } else if (refDom == 'enterpriseSubPictureRef') {
                //           this.isShowSubVueCropperDialog = false;
                //         } else {
                //           this.isShowIntroduceVueCropperDialog = false;
                //         }
                //         params == 'b_introduce_picture'
                //             ? this.$refs[refDom][0].clearFiles()
                //             : this.$refs[refDom].clearFiles();
                //         this.queryEnterpriseInfoData();
                //       } else {
                //         this.$message({
                //           type: 'error',
                //           message: `${msg}`,
                //           center: true
                //         });
                //         // 关闭裁剪弹窗
                //         if (refDom == 'enterpriseMainPictureRef') {
                //           this.isShowMainVueCropperDialog = false;
                //         } else if (refDom == 'enterpriseSubPictureRef') {
                //           this.isShowSubVueCropperDialog = false;
                //         } else {
                //           this.isShowIntroduceVueCropperDialog = false;
                //         }
                //         params == 'b_introduce_picture'
                //             ? this.$refs[refDom][0].clearFiles()
                //             : this.$refs[refDom].clearFiles();
                //       }
                //     })
                //     .catch(err => {
                //       console.log(err);
                //     });
            });
        },
        // 处理关闭裁剪图片弹窗取消按钮
        closeCropperDialog(refDom) {
            if (refDom == 'enterpriseMainPictureRef') {
                this.isShowMainVueCropperDialog = false;
            } else if (refDom == 'enterpriseSubPictureRef') {
                this.isShowSubVueCropperDialog = false;
            } else {
                this.isShowIntroduceVueCropperDialog = false;
            }
            if (refDom.indexOf('enterpriseIntroducePictureRef') > -1) {
                this.$refs[refDom][0].clearFiles();
            } else {
                this.$refs[refDom].clearFiles();
            }
        },
        checkImgSize(file, w, h, refDom) {
            return new Promise(function (resolve, reject) {
                let width = w;
                let height = h;
                let _URL = window.URL || window.webkitURL;
                let img = new Image();
                img.onload = function () {
                    let valid = img.width === width && img.height === height;
                    valid ? resolve() : reject();
                };
                img.src = _URL.createObjectURL(file);
            }).then(
                () => {
                    return file;
                },
                () => {
                    if (refDom.indexOf('enterpriseIntroducePictureRef') > -1) {
                        this.$refs[refDom][0].clearFiles();
                    } else {
                        this.$refs[refDom].clearFiles();
                    }
                    this.$message.warning(`上传图片尺寸只能是 ${w}*${h}px !`);
                    return false;
                }
            );
        },
        // 获取上传视频的信息
        getVideoInfo(file) {
            return new Promise((resolve, reject) => {
                let _URL = window.URL || window.webkitURL;
                let videoElement = document.createElement('video');
                videoElement.addEventListener('loadedmetadata', function () {
                    // 判断上传视频的宽高是否为16:9分辨率的, 最小为宽高1920*1080
                    // 位操作符取整 NOT
                    // console.log(~~(parseInt(videoElement.videoWidth) / 16) * 9);
                    // console.log(parseInt(videoElement.videoHeight));
                    // console.log(videoElement.videoHeight);
                    // console.log(videoElement.videoWidth);
                    let valid =
                        (~~(parseInt(videoElement.videoWidth) / 16) * 9 === parseInt(videoElement.videoHeight) &&
                            videoElement.videoWidth === 3840) ||
                        videoElement.videoWidth === 2560 ||
                        videoElement.videoWidth === 1980 ||
                        // videoElement.videoWidth === 1280 ||
                        videoElement.videoHeight === 2160 ||
                        videoElement.videoHeight === 1440 ||
                        videoElement.videoHeight === 1080;
                    valid ? resolve() : reject();
                });
                videoElement.src = _URL.createObjectURL(file.file);
            }).then(
                () => {
                    return file;
                },
                () => {
                    this.$message.error('请上传正确比例的视频');
                    this.$refs.enterpriseVideoRef.clearFiles();
                    return false;
                }
            );
        }
        // 处理退出登录
        // loginout() {
        //     this.$store.dispatch('user/setToken', null);
        //     removeStorage('Token', true);
        //     removeStorage('avatar', true);
        //     this.$message({
        //         type: 'success',
        //         message: '退出成功',
        //         center: true
        //     });
        //     this.$router.push({ path: '/login' });
        // }
    }
};
</script>

<style lang="scss" scoped>
.content-wrap {
    .footer-wrap {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .size {
            font-size: 14px;
            color: rgb(123, 123, 123);
        }

        .footer-btn {
            .el-button {
                width: 150px;
            }
        }
    }
}

.enterprise-info-page {
    box-sizing: border-box;
    // padding-top: 20px;
    width: 100%;

    .enterprise-info-content {
        width: 100%;
        display: flex;
        background-color: #fff;

        //.left-content,
        //.right-content {
        //    margin-top: 20px;
        //    background-color: #fff;
        //    color: #333333;
        //    border-radius: 8px;
        //    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        //}
        .left-content {
            position: relative;
            width: 330px;
            padding: 10px 10px 15px 10px;

            .enterprise-introduction {
                width: 100%;
                padding-bottom: 20px;
                //border-bottom: 1px solid #dbdbdb;
                text-align: center;

                .enterprise-logo {
                    margin-top: 20px;

                    div {
                        text-align: right;
                        margin: 10px;
                        color: #5d5d5d;
                        font-size: 13px;
                        cursor: pointer;
                        // display: flex;
                        // justify-content: space-between;
                        // align-items: center;
                    }

                    img {
                        width: 176px;
                        height: 58px;
                    }
                }

                .enterprise-name {
                    margin-top: 20px;
                }

                ul {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    width: 290px;
                    margin: 40px auto 0;

                    li {
                        h1 {
                            font-size: 26px;
                            font-weight: 700;
                        }

                        p {
                            margin-top: 10px;
                            font-size: 12px;
                            color: rgb(164, 164, 164);
                        }
                    }
                }
            }

            .enterprise-info {
                .enterprise-info-title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 15px 20px 0;

                    p {
                        position: relative;
                        margin-left: 15px;
                        font-weight: 700;
                        font-size: 16px;
                    }

                    p::before {
                        position: absolute;
                        top: 1px;
                        left: -15px;
                        width: 0px;
                        height: 20px;
                        background: rgba(0, 129, 255, 0.81);
                        border-radius: 3px;
                        content: '';
                    }

                    .el-button {
                        font-size: 14px;
                        color: rgb(123, 123, 123);
                    }
                }

                .enterprise-info-edit {
                    box-sizing: border-box;
                    width: 310px;
                    padding: 20px;

                    .el-form-item {
                        margin-bottom: 20px;
                    }

                    .el-form-item:last-child {
                        :deep(.el-form-item__label) {
                            width: 270px !important;
                            height: 28px !important;
                        }

                        :deep(.el-form-item__content) {
                            position: relative;
                            top: 30px;
                            right: 270px;
                            margin-left: 0 !important;
                            width: 270px;

                            div {
                                width: 270px;
                            }
                        }
                    }

                    :deep(.el-input) {
                        width: 180px;
                    }

                    :deep(.el-textarea),
                    :deep(.el-form-item__error),
                    div {
                        width: 250px;
                    }

                    :deep(.el-form-item__error) {
                        margin-top: 5px;
                    }

                    :deep(.el-form-item__content) {
                        width: 190px;
                    }

                    .modify {
                        position: relative;
                        top: -4px;
                        left: 20px;
                    }

                    .ellipsis {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 14; // 行数
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }

                .login-btn {
                    width: 270px;
                    height: 40px;
                    margin: 20px auto 0;
                    background-color: rgba(242, 242, 242, 0);
                    border: 1px solid rgb(221, 221, 221);
                    font-size: 13px;
                    color: #7b7b7b;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 40px;
                    cursor: pointer;

                    a {
                        color: rgb(123, 123, 123);
                    }
                }
            }
        }

        .right-content {
            // width: 100%;
            // border: 1px solid #000;
            // width: 65vw;
            padding: 20px;

            .top-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .enterprise-main-picture {
                position: relative;

                .upload-demo {
                    margin-top: 20px;

                    :deep(.el-upload-dragger) {
                        position: relative;
                        // min-width: 1000px;
                        line-height: 30px;
                    }

                    .el-icon-upload {
                        margin-top: 60px;
                    }
                }

                .hover-wrap:hover .hover-content {
                    opacity: 1;
                }

                .hover-wrap {
                    position: relative;
                    min-width: 1000px;
                    height: 180px;
                    margin-top: 20px;
                    border: 1px dashed #d9d9d9;
                    border-radius: 6px;
                    overflow: hidden;

                    // pointer-events: none; // 清除hover时频繁煽动问题
                    img {
                        width: 100%;
                        height: 100%;
                    }

                    .hover-content {
                        opacity: 0;
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.6235294117647059);
                        font-size: 12px;
                        color: #ffffff;
                        border-radius: 6px;
                        cursor: pointer;

                        div {
                            position: absolute;
                            bottom: 0;
                            width: 500px;
                            line-height: 30px;
                            text-align: center;
                        }

                        div:first-child {
                            left: 0;
                            background-color: #000;
                            border-bottom-left-radius: 6px;
                        }

                        div:last-child {
                            right: 0;
                            height: 30px;
                            background-color: #1790ff;
                            border-bottom-right-radius: 6px;

                            .el-button {
                                min-height: 30px !important;
                            }

                            .replace-image {
                                height: 30px;
                                line-height: 7px;
                                background-color: #1790ff;
                                border-color: transparent;
                            }
                        }
                    }

                    :deep(.el-image__preview) {
                        display: none;
                    }
                }

                .upload-desc {
                    font-size: 14px;
                    color: #7b7b7b;

                    .look-case {
                        margin-left: 20px;
                    }

                    :deep(.el-image__preview) {
                        display: none;
                    }
                }
            }

            .enterprise-sub-picture,
            .enterprise-introduce-picture {
                .hover-wrap:hover .hover-content {
                    opacity: 1;
                }

                .hover-wrap {
                    position: relative;
                    width: 360px;
                    height: 180px;
                    margin-top: 20px;
                    border: 1px dashed #d9d9d9;
                    border-radius: 6px;

                    // pointer-events: none; // 清除hover时频繁煽动问题
                    img {
                        width: 100%;
                        height: 100%;
                    }

                    .hover-content {
                        opacity: 0;
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.6235294117647059);
                        font-size: 12px;
                        color: #ffffff;
                        border-radius: 6px;
                        cursor: pointer;

                        div {
                            position: absolute;
                            bottom: 0;
                            width: 180px;
                            line-height: 30px;
                            text-align: center;
                        }

                        div:first-child {
                            left: 0;
                            background-color: #000;
                            border-bottom-left-radius: 6px;
                        }

                        div:last-child {
                            right: 0;
                            height: 30px;
                            background-color: #1790ff;
                            border-bottom-right-radius: 6px;

                            .el-button {
                                min-height: 30px !important;
                            }

                            .replace-image {
                                height: 30px;
                                line-height: 7px;
                                background-color: #1790ff;
                                border-color: transparent;
                            }
                        }
                    }

                    :deep(.el-image__preview) {
                        display: none;
                    }
                }
            }

            .enterprise-sub-picture,
            .enterprise-video,
            .enterprise-introduce-picture {
                position: relative;
                margin-top: 20px;

                .upload-demo {
                    position: relative;
                    width: 360px;
                    margin-top: 20px;

                    .el-icon-upload {
                        margin-top: 60px;
                    }

                    img {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        z-index: 0;
                    }
                }

                .upload-desc {
                    position: absolute;
                    top: 98px;
                    left: 370px;
                    font-size: 14px;
                    color: #7b7b7b;

                    .look-case {
                        margin-left: 20px;
                    }

                    :deep(.el-image__preview) {
                        display: none;
                    }
                }
            }

            .enterprise-introduce-picture {
                div {
                    position: relative;

                    .upload-desc {
                        top: 55px;
                    }
                }

                .add-carousel {
                    width: 274px;
                    height: 38px;
                    margin: 50px 0 30px 43px;
                    border: 1px solid #dddddd;
                    font-size: 13px;
                    color: #7b7b7b;
                    text-align: center;
                    line-height: 38px;
                    cursor: pointer;
                }
            }
        }
    }
}

.rightcontent {
    width: 100%;
}

.colorRed {
    font-size: 12px;
    color: red;
}
</style>