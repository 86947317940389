import request from '@/utils/http.js';

/**
 * 一级分类管理列表
 * @param data
 */
export function getList(data) {
    return request.get('/brand_manage', data,'brand-manage-page');
}

export function save(data) {
    return request.post('/brand/save', data, 'multipart/form-data');
}

export function getMsg(data) {
    return request.post('/brand/get', data);
}

export function edit(data) {
    return request.post('/brand/edit', data, 'multipart/form-data');
}

export function examine(data) {
    return request.post('/brand/examine', data);
}

export function del(data) {
    return request.post('/brand/delete', data);
}

export function download(data) {
    return request.export('/brand/export', data, '品牌管理.xlsx');
}

export function enable(data) {
    return request.post('/brand/enable', data);
}

export function deletePicture(data) {
    return request.post('/brand/delete_picture', data);
}

/**
 * 获取品牌审核数据
 * @param data
 */
export function getBrandAuditData(data) {
    return request.post('/to_examine', data);
}

/**
 * 品牌审核操作
 * @param data
 */
export function brandAuditAction(data) {
    return request.post('/set_status_brand', data);
}

/**
 * 审核获取品牌详情
 * @param data
 * @returns {*}
 */
export function getExamineinfo(data){
    return request.get('/brand_info',data);
}



